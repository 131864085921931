.SpottedComponent{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sendSpottedBox{
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media only screen {
    .Spotted{
        width: 320px;
    }

    .sendSpottedBox .spottedInput{
        width:calc(100% - 40px);
        margin-left: 20px;
        margin-right: 20px;
        height:200px;
        resize:none;
    }

    .spottedButton{
        width: calc(100% - 40px);
    }

    .spottedAlert{
        width: calc(100% - 40px) !important;
    }

    .bemvindoLabel{
        font-size: 18px;
        text-align: center;
    }

    .spottedCommunityImg {
        height: 140px;
        width: 140px;
    }

    .gray-line {
        width: calc(100% - 40px); 
        height: 2px; 
        background-color: #6e6e6e; 
        margin: 15px;
    }
}

@media only screen and (min-width:320px) {

    .Spotted{
        width: 100%;
    }

    .sendSpottedBox{
        width: 100%;
    }

    .sendSpottedBox .spottedInput{
        width:calc(100% - 40px);
        margin-left: 20px;
        margin-right: 20px;
        height:200px;
        resize:none;
    }

    .spottedButton{
        width: calc(100% - 40px);
    }

    .spottedAlert{
        width: calc(100% - 40px) !important;
    }
    

    .bemvindoLabel{
        font-size: 18px;
        text-align: center;
    }

    .spottedCommunityImg {
        height: 160px;
        width: 160px;
    }

    .gray-line {
        width: calc(100% - 40px); 
        height: 2px; 
        background-color: #6e6e6e; 
        margin: 15px;
    }
}

@media only screen and (min-width:370px) {
    .sendSpottedBox{
        width: 100%;
    }

    .sendSpottedBox .spottedInput{
        width:calc(100% - 40px);
        margin-left: 20px;
        margin-right: 20px;
        height:200px;
        resize:none;
    }

    .spottedButton{
        width: 350px;
    }

    .spottedAlert{
        width: 350px !important;
    }

    .bemvindoLabel{
        font-size: 24px;
        text-align: center;
    }

    .spottedCommunityImg {
        height: 180px;
        width: 180px;
    }

    .gray-line {
        width: 350px; 
        height: 2px; 
        background-color: #6e6e6e; 
        margin: 15px;
    }
}



@media only screen and (min-width:480px) {
    .sendSpottedBox{
        width: 100%;
    }

    .sendSpottedBox .spottedInput{
        width:calc(100% - 40px);
        margin-left: 20px;
        margin-right: 20px;
        height:200px;
        resize:none;
    }

    .spottedButton{
        width: 350px;
    }

    .bemvindoLabel{
        font-size: 24px;
        text-align: center;
    }

    .gray-line {
        width: 400px; 
        height: 2px; 
        background-color: #6e6e6e; 
        margin: 15px;
    }
}

@media only screen and (min-width: 715px) {
    .sendSpottedBox .spottedInput{
        width:700px;
        height:200px;
        resize:none;
    }

    .spottedButton{
        width: calc(100% - 40px);
    }

    .spottedButton{
        width: 350px;
    }

    .bemvindoLabel{
        font-size: 24px;
        text-align: center;
    }
}

.Spotted{
    margin-top: 20px;
    margin-bottom: 20px;
}

.spottedEnviadoBox{
    display: flex;
    flex-direction: column;
    align-items: center;
}

textarea:focus {
    border-color: rgba(249, 83, 83, 0.8); 
    box-shadow: 0px 0px 4px rgba(249, 83, 83, 0.8);
    outline: 0 none;
}

.ReactLoadingBox{
    height: calc(100vh - 150px);
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 100px;
}

.NPSNotasSection{
    width:290px;
    display: flex;
    flex-direction: row;
    flex-wrap:wrap;
    align-items: space-between;
    justify-content: center;
}

.NPSNotasButton{
    width:48px;
    height:48px;
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
    border: 3px #6050DC solid;
    margin: 5px;
    font-size: 18px;
    font-weight: bold;
    color: #6050DC;
}

.NPSNotasButton:hover{
    width:48px;
    height:48px;
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
    border: 3px #6050DC solid;
    margin: 5px;
    font-size: 18px;
    font-weight: bold;
    background-color: #6050DC;
    color: #FFF;
}

.imageUploadSection {
    width: calc(100% - 40px);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px 0;
}

.imageUploadButton {
    width: 200px !important;
    margin: 0px 0px !important;
    padding: 0px 0px;
}

.imageUploadSection {
    margin: 0px 0px !important;
    padding: 0px 0px;
}

.imagePreviewContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
}

.imagePreview {
    max-width: 100%;
    max-height: 300px;
    border-radius: 8px;
    margin-bottom: 10px;
    object-fit: contain;
}

.removeImageButton {
    width: 120px !important;
}

/* Crop Modal Styles */
.ReactCrop {
    max-width: 100%;
    background-color: #f5f5f5;
    border-radius: 8px;
}

.ReactCrop__crop-selection {
    border: 2px solid #f95353 !important;
}

.ReactCrop__drag-handle {
    background-color: #f95353 !important;
}

.ReactCrop__drag-bar {
    background-color: #f95353 !important;
}

.ReactCrop__drag-handle::after {
    background-color: #f95353 !important;
}
