.ApproveSpotteds{
    width: 100%;
    height: 100vh;
  
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: "Montserrat", sans-serif;
    font-size: 1rem;
    font-weight: 400;
    font-size: 18px;
  
  }
  
.ApproveSpotteds .Box{
    width: 320px;
    padding-left: 15px;
    padding-right: 15px;

    border:1px solid gray;
    border-radius: 7px;
}

.ApproveSpotteds .authBox{
    width: 320px;
    padding-left: 15px;
    padding-right: 15px;

    border:1px solid gray;
    border-radius: 7px;
}
.ApproveSpotteds .dropdown-menu{
    max-height: 300px;
    overflow-y: auto;
}